.landing {
    display: flex;
    flex-direction: column;
    gap: var(--padding-sm);
    padding: var(--padding);
}

.landing>.welcome {
    display: flex;
    flex-direction: column;
    gap: inherit;
    margin: var(--padding-sm);
}

@media screen and (min-width: 849px) {
    .landing>.welcome {
        flex-direction: row;
        gap: var(--joy-fontSize-xl4);
        margin: var(--joy-fontSize-xl4) 0;
        align-items: center;
    }
}

.landing>.welcome>h1,
.landing>.welcome>p {
    flex: 1;
}

.landing>.welcome>h1 {
    font-size: var(--joy-fontSize-xl3);
}

@media screen and (min-width: 849px) {
    .landing>.welcome>h1 {
        font-size: var(--joy-fontSize-xl4);
        text-align: right;
    }
}

.landing>.welcome>p {
    display: flex;
    flex-direction: column;
    gap: .25em;
}

.landing>.welcome>p>button {
    margin-top: var(--padding);
}

@media screen and (min-width: 849px) {
    .landing>.welcome>p>button {
        margin-top: 1em;
    }
}

.landing>.structure>h2,
.landing>.questions>h2,
.landing>.method>h2 {
    margin-bottom: .25em;
}

.landing>.structure>p,
.landing>.method>p {
    font-weight: bold;
}

.landing>.structure>p:first-of-type,
.landing>.method>p:first-of-type {
    font-weight: inherit;
}

.landing>.structure .MuiList-root,
.landing>.structure .MuiListItem-root {
    margin-top: -.5em;
    /*-.25em*/
}

.landing>.method .MuiList-root,
.landing>.method .MuiListItem-root {
    margin-top: -.5em;
}

.landing>.question-bank>div {
    display: flex;
    flex-direction: column;
    gap: inherit;
}

@media screen and (min-width: 769px) {
    .landing>.question-bank>div {
        flex-direction: row;
        align-items: center;
        gap: 5em;
    }
}

.landing>.question-bank img {
    background-color: white;
    border-radius: var(--joy-radius-md);
    box-shadow: var(--joy-shadow-md);
    text-align: center;
    box-sizing: border-box;
    max-width: 100%;
}

.landing>.question-bank>div>div:first-child {
    display: flex;
    flex-direction: column;
    gap: var(--padding);
}

.landing>.question-bank>div>div:first-child img {
    display: block;
    margin: var(--padding);
}

@media screen and (min-width: 769px) {
    .landing>.question-bank>div>div:first-child img {
        display: none;
    }
}

.landing>.question-bank>div>div:first-child>ul>li {
    gap: 1em;
}

.landing>.question-bank>div>div:first-child>button {
    margin-top: .375em;
}

.landing>.question-bank>div>div:last-child {
    display: none;
    flex: 1;
}

@media screen and (min-width: 769px) {
    .landing>.question-bank>div>div:last-child {
        display: block;
    }
}