footer {
  display: flex;
  flex-direction: column;
  margin-top: calc(-1 * var(--padding-sm));
  padding: calc(var(--padding) * 2) var(--padding);
  gap: var(--padding-sm);
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 769px) {
  footer {
    flex-direction: row;
    margin-top: calc(-1 * var(--padding));
  }
}

footer>*:first-child {
  display: flex;
  justify-content: center;
  gap: inherit;
}

footer>*:first-child>* {
  display: flex;
  justify-content: center;
  text-align: center;
  flex: 1;
}

@media screen and (min-width: 361px) {
  footer>*:first-child>* {
    white-space: pre;
  }
}

@media screen and (min-width: 769px) {
  footer>*:first-child>* {
    flex: none;
  }
}

footer>p:first-of-type {
  display: none;
}

@media screen and (min-width: 769px) {
  footer>p:first-of-type {
    display: block;
  }
}