header {
    font-size: var(--joy-fontSize-sm);
    display: flex;
    align-items: center;
    padding: var(--padding-xs) var(--padding-sm);
    background-color: var(--joy-palette-primary-softBg);
    z-index: 100;
    justify-content: space-between;
    position: sticky;
    top: 0;
}

@media screen and (min-width: 361px) {
    header {
        font-size: var(--joy-fontSize-md);
    }
}

@media screen and (min-width: 481px) {
    header {
        font-size: var(--joy-fontSize-xl);
    }
}

header nav {
    display: none;
}

@media screen and (min-width: 1024px) {
    header .MuiIconButton-root {
        display: none;
    }

    header nav {
        display: flex;
        align-items: center;
        justify-content: right;
        flex: 1;
        gap: .5em;
        white-space: nowrap;
    }
}

header .MuiButton-variantOutlined.MuiButton-colorPrimary:hover {
    background-color: var(--joy-palette-neutral-100) !important;
}