.authentication-modal {
    --authentication-theme-color: var(--joy-palette-primary-100);
    --authentication-background-color: white;
    --authentication-padding: var(--padding);
    --authentication-gap: var(--padding-xs);
    --authentication-animation: .3s ease;
    --authentication-divider: 1px solid rgba(0, 0, 0, .25);
    --authentication-dialog-title-padding: calc(.5 * var(--authentication-padding));
    display: flex;
    align-items: center;
}

@media screen and (min-width: 481px) {
    .authentication-modal {
        padding: var(--authentication-padding);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.authentication-modal .MuiModal-backdrop {
    animation: fade-in var(--transition);
}

@keyframes fly-in {
    0% {
        transform: translateY(-1rem);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes fly-in-dialog {
    0% {
        transform: translate(-50%, calc(-50% - 1rem));
    }

    100% {
        transform: translate(-50%, -50%);
    }
}

.MuiModalDialog-root {
    animation: fade-in var(--transition), fly-in-dialog var(--transition);
}

.authentication-wrapper {
    width: 'fit-content';
    width: 100%;
    max-width: 480px;
    transition: height var(--authentication-animation);
    background-color: var(--authentication-background-color);
    min-height: 100%;
    max-height: 100%;
    overflow: auto;
    outline: none !important;
}

@media screen and (min-width: 481px) {
    .authentication-wrapper {
        margin: auto;
        border-radius: var(--joy-radius-md);
        box-shadow: var(--joy-shadow-md);
        min-height: auto;
        animation: fade-in var(--transition), fly-in var(--transition);
    }
}

.authentication-wrapper>form {
    display: flex;
    flex-direction: column;
    gap: var(--authentication-gap);
    padding: var(--authentication-padding);
}

.authentication-wrapper>form>p {
    text-align: center;
}

.authentication-wrapper>form>button {
    width: 100%;
}

.authentication-wrapper .MuiDialogTitle-root {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--authentication-theme-color);
    margin: calc(-1 * var(--authentication-padding));
    margin-bottom: calc((-.5 * var(--authentication-gap)) + var(--authentication-dialog-title-padding));
    padding: calc(var(--authentication-padding));
    padding-bottom: calc((.5 * var(--authentication-gap)) + var(--authentication-dialog-title-padding));
}

@media screen and (min-width: 481px) {
    .authentication-wrapper .MuiDialogTitle-root {
        display: none;
    }
}

#authentication-password-constraints {
    display: none;
}

.authentication-divider {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.authentication-divider>p {
    z-index: 1;
    background-color: var(--authentication-background-color);
    padding: 0 1em;
}

.authentication-divider::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 50%;
    right: 0;
    border-bottom: var(--authentication-divider);
}

.authentication-compliance {
    text-align: left !important;
}