.question-bank-item>div:first-of-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: .5em;
    overflow: auto;
    margin: 0 -16px;
    padding: 0 16px;
}

.question-bank-item>div:first-of-type>div:first-of-type {
    display: flex;
    gap: .5em;
}

.question-bank-item .MuiAccordionGroup-root {
    border-radius: var(--joy-radius-md);
    overflow: hidden;
}

.question-bank-item .MuiAccordion-root:last-of-type {
    border-bottom: none;
}

.question-bank-item .MuiAccordionSummary-root>[aria-expanded="true"] {
    background-color: var(--joy-palette-neutral-plainHoverBg);
}

.question-bank-item .MuiAccordionSummary-root>[aria-expanded="true"]:hover {
    background-color: var(--joy-palette-neutral-plainActiveBg);
}

.question-bank-item .MuiList-root {
    margin-top: 0;
}

.question-bank-item .MuiAccordionDetails-root {
    margin: 0;
    padding: 0;
}

.question-bank-item .MuiAccordionDetails-root.Mui-expanded {
    margin: 0;
    padding: 0;
    margin-top: -.5em;
}

.question-bank-item .MuiAccordionDetails-content {
    position: relative;
    top: .5em;
    padding: .5em;
    padding-bottom: 1.5em;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: .5em;
}

/* lists */
.question-bank-item .MuiList-root {
    margin-top: -.25em;
}

.question-bank-item .MuiListItem-root {
    margin-top: -.5em;
}

.question-bank-item .MuiAccordionDetails-content>.MuiList-root,
.question-bank-item .MuiAccordionDetails-content>p:first-child {
    margin-top: .375em;
}

.question-bank-item .MuiAccordionDetails-content>ol {
    margin-left: .25em;
    margin-bottom: -.25em;
}

.question-bank-item .MuiAccordionDetails-content>ul {
    margin-left: -.25em;
    margin-bottom: -.375em;
}
